import React,{useState} from 'react'
import { Link } from 'react-router-dom';



const Report_dash = () => {
   
   
    return (
  
  
      <div className='bg_img_c'>
        
        <img className='center1' style={{position:'fixed' , zIndex:0}} src='.././logo2.png' />    
      
        <div className='center1' style={{marginTop:'40px'}}>
        
      
            <div className='container2' >
 
           <div className="list" >
           <h2>التقارير</h2>
    <div className="container_dash" dir='rtl'>
    
        

 
       

  
    <Link className='dash_item'  to= "/reports/purchase">
    <img className='utilities' src={".././paid1.png"}/>
  
    <p className='dash_item_title'><b> المبيعات </b>  </p>
    </Link>
          
    <Link className='dash_item'  to= "/reports/reserved" >
    <img className='utilities' src={".././reserved.png"}/>
  
    <p className='dash_item_title'><b> الحجوزات </b>  </p>
    </Link>


    <Link className='dash_item'  to= "/reports/available" >
    <img className='utilities' src={".././for-sale1.png"}/>
  
    <p className='dash_item_title'><b> المتاحات </b>  </p>
    </Link>
             
    <Link className='dash_item'  to= "/reports/accounts">
    <img className='utilities' src={".././report_cash.png"}/>

    <p className='dash_item_title'><b> كشف حساب </b>  </p>
    </Link>
      
  
    
    </div>
    </div> 
    <br></br>
    </div> 
    <br></br>
    </div>
       </div>
  
)
  }

export default Report_dash;










import React, {useEffect,useState} from 'react'
import axios from 'axios';
import Elements from './Elements';
import {  useParams } from "react-router-dom";


import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';
import {useAuthUser} from 'react-auth-kit'
import Spinner from 'react-bootstrap/Spinner';
const Adddesign = () => {
 
  const auth = useAuthUser();  


  let params = useParams();

  const [out, setOutput] = useState({
    cat: '0',
    subcat: '0',
    count:1,
    d_id:params.id,
  
  });
  
  useEffect( () => {

   
  
    cat();

    

   

}, []); 

const [spin, setSpin] = useState(false);


const [emp, setEmp] = useState([]);
const [emp1, setEmp1] = useState([]);




const cat = async () => {


  try {
   
    axios.get(`https://al-ansi.net/api/categories.php`)
    .then(res => {
     
      setEmp(res.data.productlist.productdata);
     

    })
  } catch (error) { throw error;}    

}





const onChangeValue = (e) => {
  setOutput({
    ...out,
    [e.target.name]:e.target.value
  });

  
}

const onChangeValue1 = (id) => {
  setOutput({
    ...out,
    ['cat']:id
    , ['subcat']:0
  } );
  const value = id;

  setEmp1([]);
  subcat(value);
  
} 



const onChangeValue2 = (id) => {

  if(Number(id) > 0){

    setOutput({
      ...out,
      ['subcat']:id
    });
  }
else{
  alert('لا يوجد  بيانات')
  window.location.reload(false);
}


  
} 


const subcat = async (cat) => {
  
  setSpin(true)
  try {
   
    axios.get(`https://al-ansi.net/api/subcat.php?cat=`+cat)
    .then(res => {
     
    
      setEmp1(res.data.productlist.productdata);
      setSpin(false)
     
    })
  } catch (error) { throw error;}    

}


const [project, setProject] = useState([]);


const [loading, setLoading] = useState(false);

const allproduct = async (id) => {
  
  setLoading(true);
  try {
   

   
 
    
    axios.get('https://al-ansi.net/api/elements.php?d_id='+id)
    .then(res => {
      
      setProject(res.data.productlist.productdata);
      setLoading(false);
    })
  } catch (error) { throw error;}    
 
}




const add = async(event) => {
  setLoading(true);
   
  try {
    event.preventDefault();
    event.persist();

    var formData = new FormData();

 
    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
 
    
    formData.append("cat_id",out.cat); 
    formData.append("sc_id",out.subcat); 
    formData.append("d_id",out.d_id);


    axios.post(`https://al-ansi.net/api/add_des_det.php`, formData)
    .then(res => {
          setLoading(false);
      alert(res.data.msg);
      
      window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};





  
return (
  
         <div>

{loading ? (
    <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
      ) : (  
        <div>
        
   
  <div className="list" style={{marginTop:90,marginBottom:50}}>

   
    
   <div dir='rtl' style={{width:'90% ', maxWidth:'1000px'}} class="form-container">
        
   
        
        
            <p class="title">  اضافة عنصر لمجموعة </p>
        
             
                <hr></hr>
   <form onSubmit={add}>


   <div dir='' class="form-group">
   <div style={{width:'100%'}} className="flex">

  
   

<DatalistInput
style={{color:'#1a1a1a'}}
dir='rtl'
    placeholder="Search and choose..."
    label=" النوع :"
    onSelect={(item) => onChangeValue1(item.id)}
    items={ emp.map(option => (

      { id: option.value, value:option.label }
      
      ))

    }
  />

  
  &emsp; &emsp;

  {
 spin ?  <Spinner animation="border" />
 :
<DatalistInput
style={{color:'#1a1a1a'}}
dir='rtl'
    placeholder="Search and choose..."
    label=" تفصيل النوع :"
    onSelect={(item) => onChangeValue2(item.id)}
    items={ emp1.map(option => (

      { id: option.value, value:option.label }
      
      ))

    }
/>
  }
  </div>
  <hr></hr>

   <input type="submit" value="اضافة" />

 

  </div>
  
  
  
 
   
  
   
  </form>
  </div>
  <br></br>
  </div>
  <Elements/>

  </div>
      )}
  </div>
)
}


export default Adddesign;










import React, {useEffect,useState} from 'react'
import {  Outlet, useParams } from "react-router-dom";


import Footer from '../Footer';

import Header_in from '../Header_in';

const Building = () => {
 
    let params = useParams();




    useEffect( () => {
     
      window.localStorage.setItem("b_id",params.ids);

  


     
    
    }, []); 
    

        

    

   
    
    const [param, setParam] = useState({
      ids:params.ids,
     
    });
    
    
  
    return (
      
      <div style={{ height:'100vh'}}>
    <Header_in list={param}/>
    <br></br> 
     
<Outlet  />


   <br></br> 
         <Footer/>
      </div>
    )
    }
    



export default Building;










import React, {useEffect,useState} from 'react'
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Header from '../Header';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import {useAuthUser} from 'react-auth-kit'

const Add_subutl = () => {

  const auth = useAuthUser();  





useEffect( () => {

   
  utilities();
 
    subcat()
  
}, []); 


const [loading, setLoading] = useState(false);



const [cat, setCat] = useState([]);

const utilities = async () => {
  
  setLoading(true);
  try {
   

   
 
    
    axios.get(`https://al-ansi.net/api/utilities.php`)
    .then(res => {
      
      setCat(res.data.productlist.productdata);
      setLoading(false);

  
      
    })
  } catch (error) { throw error;}    
 
}




    
const [data, setData] = useState([]);
const subcat = async () => {
  setLoading(true);

  try {
   
    axios.get(`https://al-ansi.net/api/allsubutl.php`)
    .then(res => {
     
    
      setData(res.data.productlist.productdata);
      
      setLoading(false);
    })
  } catch (error) { throw error;}    

}







const onChangeValue = (e) => {
  setOutput({
    ...out,
    [e.target.name]:e.target.value
  });

  
} 

const [out, setOutput] = useState({
  cat: '0',
  name: '',
});


const add = async(event) => {
  setLoading(true);
   
  try {
    event.preventDefault();
    event.persist();

    var formData = new FormData();
    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
 
   
    formData.append("cat",out.cat); 
    formData.append("name",out.name); 


    axios.post(`https://al-ansi.net/api/add_subutl.php`, formData)
    .then(res => {
          setLoading(false);
      alert(res.data.msg);
      
      window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};








const product = async (id) => {
  
  setLoading(true);
  try {
   

   
 
    
    axios.get('https://al-ansi.net/api/get_subutl.php?su_id='+id)
    .then(res => {
      if(res.data.success === true){

        setOutput({
          ...out,
          ['name']:res.data.name
        });
       
        setLoading(false);
      }else{
        alert(res.data.msg)
        setLoading(false);
      }
    
    })
  } catch (error) { throw error;}    
 
}



const update = async() => {
  setLoading(true);
   
  try {


    var formData = new FormData();

    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
 
   
    
    formData.append("cat_id",out.cat); 
    formData.append("su_id",sc_id); 
    formData.append("name",out.name); 


    axios.post(`https://al-ansi.net/api/edit_subutl.php`, formData)
    .then(res => {
          setLoading(false);
      alert(res.data.msg);
      
      window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};

const [sc_id, setSc_id] = useState('');

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = (id) =>{
 if(window.confirm( ` هل تريد تعديل التشطيب   `)) {

  setSc_id(id)
 product(id)
  setShow(true);
 }
  
} 






var r=0;
var c=0;
var b=0;
var d=0;
return (
  
  <div >

     {loading ? (
      <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
        
      ) : ( 
<div>
<Modal  style={{color:'#999'}} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title >تعديل عنصر </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
      
  <div className="list" >

   
    
<div dir='rtl' style={{width:'90% ', maxWidth:'1000px'}} class="form-container">
     

     
     
         <p class="title">  تعديل عنصر  </p>
     
          
             <hr></hr>
<form onSubmit={update}>


<div dir='' class="form-group">
<div style={{width:'100%'}} >



</div>


  

        <Form.Group  as={Col} >
        <Form.Label>الأسم </Form.Label>
          <Form.Control name='name' required value={out.name} onChange={onChangeValue} type="text" placeholder="الأسم  " />
        </Form.Group>
    
      <hr></hr>

<Button variant='warning' type='submit'>تعديل</Button>

</div>







</form>
</div>
<br></br>
</div>
           
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>
   

   
        <div className="list" style={{marginTop:90,marginBottom:50}}>
<h1>اضافة تشطيب جديد</h1>
<br></br>
  <Form dir="RTL" style={{width:'90%'}}  onSubmit={add}>
     
<Row className="mb-3">
  <Form.Group  as={Col}>
          <Form.Label>نوع التشطيب</Form.Label>
        <Form.Select name='cat' required onChange={onChangeValue} aria-label="Default select example">
      <option value=''>choose</option>
      {cat.map(option => (
      
      <option  key={option.utl_id} value={option.utl_id}>
        {option.name}
      </option>
    ))}
    </Form.Select>
    </Form.Group>

        <Form.Group  as={Col} >
          <Form.Label>الأسم </Form.Label>
          <Form.Control name='name' required onChange={onChangeValue} type="text" placeholder=" الأسم " />
        </Form.Group>
        </Row>
      <hr></hr>
      <Button  type="submit">
        اضافة
      </Button>
    </Form>
<br></br>
</div>






<Table  responsive striped bordered hover variant="light">
    <thead>
    <tr>
   
  <th scope="col">تعديل</th>
  <th scope="col">تاريخ الانشاء</th>
  <th scope="col">المستخدم</th>
    <th scope="col">الغنصر</th>
    <th scope="col">النوع </th>

    
    
   
    
       
   
   
  
        </tr>
        </thead>
  <tbody>
  {data.map((item,index)=>(
   
   

      

        <tr>
 
        <td><Button variant="outline-warning" onClick={() =>handleShow((item.value))}>
          تعديل
        </Button></td> 
        <td>{item.date}</td>
        <td>{item.u_name}</td>
        <td>{item.label}</td>
        <td>{item.ut_name}</td>
    
        
        {
            item.utl_id === "1" ?

        <td className='hide' >{r += 1 } </td>
        
            : item.utl_id === "2" ?

        <td className='hide' >{b += 1 } </td>

             : item.utl_id === "3" ?

        <td className='hide' >{c +=1 } </td>

: <td className='hide' >{d += 1 } </td>


         }
       
       </tr>
     
  

 

 
))}

</tbody>

   
<tfoot>
  

  <tr>
    <td colSpan={4}><b>{r}</b></td>
    <th  scope="row"> بلاط</th>
    </tr>
  
  
    <tr>
    <td colSpan={4}><b>{b}</b></td>
    <th  scope="row"> ديكور </th>
    </tr>
  
    <tr>
    <td colSpan={4}><b>{c}</b></td>
    <th  scope="row">دهان</th>
    </tr>
  
    <tr>
    <td colSpan={4}><b>{d}</b></td>
    <th  scope="row">كهرباء</th>
    </tr>
  
  
    </tfoot>
</Table>






</div>)}






  </div>
)
}


export default Add_subutl;










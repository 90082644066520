
import { useEffect, useState } from "react";
import {Link} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import axios from 'axios';
import { useSignOut } from "react-auth-kit";
import {useAuthUser} from 'react-auth-kit'
import Modal from 'react-bootstrap/Modal';
import Operation from "./operations/Operations";
const Header = () => {
   

 
  const logout = useSignOut();

  const auth = useAuthUser();  
    

  const [userInfo, setuserInfo] = useState({});

  useEffect(()=>{
    user1();
},[])


  const logOut =()=>{
    logout();
      localStorage.removeItem('key');
      localStorage.clear();
      
  
      window.location.reload(false);
  
  }


  var expand ="lg";



  const user1 = async () => {
 

    try {
      var username =  auth().username;
      var key = auth().key;
        
      axios.get(`https://al-ansi.net/api/userinfo.php?key=`+key+`&username=`+username)
      .then(res => {
      
        console.log(res.data.userinfo.userdata);
        setuserInfo(res.data.userinfo.userdata[0]);
        
      })
     
    } catch (error) { throw error;}    
  }


  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  

return (
  <div id='head'>

  
 
 <Navbar   fixed="top"  expand={false}  bg="dark" data-bs-theme="dark">
          
                
 <Container fluid>
          <Navbar.Brand  ><Link style={{color:'white'}} to='/myprofile'>
            <img
             className="profile_i"
              alt=""
              src={'https://al-ansi.net/api/profile/'+userInfo.picture}  
              width="30"
              height="30"
         
            />{' '}
              {auth().username}
              ({auth().type})
              </Link>
          </Navbar.Brand>
          <Navbar.Brand > <Link to='/'> <img
           
           alt=""
           src={'https://al-ansi.net/logo2.png'}  
          
           width="50px"
      
         /></Link></Navbar.Brand>
          <Button onClick={handleShow} style={{border:'none',background:'transparent'}}><img style={{width:'30px',}}  src="https://al-ansi.net/notification.png" /></Button>
           
           
            <Navbar.Toggle  className="bg-light" onClick={()=>setShow1(!show1)}  />
           
           
            <Offcanvas  show={show1} onHide={handleClose1} data-bs-theme="dark">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                Future Swift
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
               

               

                   
                      
                    <Link  onClick={handleClose1} style={{borderRadius:10,width:'100%',backgroundColor:'GrayText',color:"white",textAlign:'center'}} to='addbuilding'>
                    ادارة الأبراج  
                    </Link>
                 
                   
                    <br />
                 
                    <Link onClick={handleClose1} style={{borderRadius:10,width:'100%',backgroundColor:'GrayText',color:"white",textAlign:'center'}}  to=''>
                    عرض الابراج  
                    </Link>
              
              
                    <hr />
                    <Link onClick={handleClose1} style={{borderRadius:10,width:'100%',backgroundColor:'GrayText',color:"white",textAlign:'center'}}   to='d_group'>
                    المجموعات و العناصر 
                    </Link>
                
                    
             
                    
                    <hr />
             
                    <Link onClick={handleClose1} style={{borderRadius:10,width:'100%',backgroundColor:'GrayText',color:"white",textAlign:'center'}}   to='addsubcat'>
                    ادارة انواع العناصر
                    </Link>
                    <br />
         
                    <Link onClick={handleClose1} style={{borderRadius:10,width:'100%',backgroundColor:'GrayText',color:"white",textAlign:'center'}}  to='addsubutl'>
                    ادارة انواع التشطيبات  
                    </Link>
                 
              
             

                 
                  <br></br>
            

                </Nav>
              </Offcanvas.Body>
              <button style={{bottom:10, left:15}} class="Btn">
  
  <div class="sign"><svg viewBox="0 0 512 512"><path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path></svg></div>
  
  <div class="text" onClick={logOut}>Logout</div>


</button>
              <Navbar.Text dir="rtl" style={{margin:10}}>
            Developed by : <a href="#login">Future Swift</a>
          </Navbar.Text>
          </Offcanvas>
          </Container>
        </Navbar>



        <Modal show={show} style={{color:'#999'}} backdrop="static" onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title>  اخر العمليات</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        </Modal.Body>
       
        <div dir='rtl' >
  
    
  <div>    
  <Operation/>

        </div>
   
    
       </div>
        <Modal.Footer>

        </Modal.Footer>

        </Modal>
 
  </div>
)
  }

export default Header;










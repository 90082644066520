import React from 'react'



import Header_1 from './Header_1';
import Dash from './Dash';
import Footer1 from './Footer1';
import { Outlet } from 'react-router-dom';


const Dashboard = () => {


    return (
        <div >

        <Header_1 />

  
       <Outlet />
      
             <Footer1/>
             </div>
)
  }

export default Dashboard;










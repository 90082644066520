import React, {useEffect,useState} from 'react'
import axios from 'axios';

import Header_in from '../Header_in';

import {  useParams } from "react-router-dom";


const Utilities = () => {
 
    let params = useParams();


 

    useEffect( () => {

  
        allproduct();
       
    
    }, []); 
    
    
    
    
    
    const [apartsec, setApartsec] = useState([]);
    
    
    
    const [loading, setLoading] = useState(false);
    
    const allproduct = async () => {
      setLoading(true)
    
      try {
        
        axios.get('https://al-ansi.net/api/utilities.php')
        .then(res => {
          
          setApartsec(res.data.productlist.productdata);
          setLoading(false);
        })
      } catch (error) { throw error;}    
     
    }
    
    
    return (
      

      
        <div className='bg_img1' >

      
     
        {loading ? (
          <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
            
          ) : ( 
    
    
            <div className='container2'>
            <div className="list">
            <h1>  نوع التشطيبات لمخطط {params.num}</h1>
      <div className="container1">
    
    
      {apartsec.map((item,index)=>(
        <a href={`add_utl/${item.utl_id}/${params.num}/${item.name}/${item.img}`} className="apartdes1">
    
        
                
    <img className='utilities' src={"https://al-ansi.net/api/utl_icon/"+item.img}/>
                    
               
                  
                  <p style={{width:'100px',fontSize:'20px'}} className='title'><b>{item.name}</b></p> 
    
                  </a>
     
    ))}
    <a href={``} className="apartdes1">
    
        
                
    <img className='utilities' src={"https://al-ansi.net/api/utl_icon/area.png"}/>
                    
               
                  
    <p style={{width:'100px',fontSize:'20px'}} className='title'><b>المساحة</b></p> 
    
                  </a>
    </div>
     </div>
    </div>
          )}
      </div>
      
    )
      }

export default Utilities;










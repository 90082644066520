import React from 'react'


import Getapartments from './Getapartments';

const Floor = () => {
   

return (
  
  <div className='bg_img'>


<Getapartments />
     
  </div>
  
)
  }

export default Floor;










import React from 'react'
import { Link } from 'react-router-dom';



const Clients = () => {
   
   
    return (
  
  
      <div className='bg_img_c'>
        
        <img className='center1' style={{position:'fixed' , zIndex:0}} src='.././logo2.png' />    

        <div className='center1' style={{marginTop:'40px'}}>
        
      
            <div className='container2' >
 
           <div className="list" >
           <h2>العملاء</h2>
    <div className="container_dash" dir='rtl'>
    
        

    <Link className='dash_item' to= "/clients/detail">
    <img className='utilities' src={".././clients.png"}/>

    <p className='dash_item_title'><b> العملاء </b>  </p>
    </Link>
      
       

  
    <Link className='dash_item'  to= "/clients/purchase">
    <img className='utilities' src={".././purchase.png"}/>
  
    <p className='dash_item_title'><b> المبيعات </b>  </p>
    </Link>
      


        
    <Link className='dash_item'  to= "/clients/payments">
    <img className='utilities' src={".././cash.png"}/>

    <p className='dash_item_title'><b> التسديدات </b>  </p>
    </Link>
      
         

             
    <Link className='dash_item'  to= "/clients/account">
    <img className='utilities' src={".././report_cash.png"}/>

    <p className='dash_item_title'><b> كشف حساب </b>  </p>
    </Link>
      
  
             
    <Link className='dash_item'  to= "/clients/special">
    <img className='utilities' src={".././interior-d.png"}/>
    
    <p className='dash_item_title'><b> طلبات تشطيب </b>  </p>
    </Link>
    
    </div>
    </div> 
    <br></br>
    </div> 
    <br></br>
    </div>

       </div>
  
)
  }

export default Clients;










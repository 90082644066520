import React, {useEffect,useState} from 'react'
import axios from 'axios';
import {Link} from "react-router-dom";

import {  useParams } from "react-router-dom";


const Getapartments = () => {
    let params = useParams();


  useEffect( () => {
 
    allproduct(params.fid,localStorage.getItem("b_id"));
   

}, []); 





const [project, setProject] = useState([]);








const [loading, setLoading] = useState(false);

const allproduct = async (fid,b_id) => {
  
  setLoading(true);
  try {
   

   
 
    
    axios.get('https://al-ansi.net/api/apartments.php?fid='+fid+"&b_id="+b_id)
    .then(res => {
      
      setProject(res.data.productlist.productdata);
      setLoading(false);
    })
  } catch (error) { throw error;}    

}


return (
  
    <div className='bg_img'>

<img className='center1' style={{position:'fixed' , zIndex:0}} src='../../../.././logo2.png' />
 
    {loading ? (
      <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
        
      ) : ( 

        <div  className='container2'>
        <div className="list" style={{marginTop:90,marginBottom:50}}>
        <h1>{ params.fn+ 'دور رقم'}</h1>
  <div className="container1">
  {project.map((item,index)=>(
    
    
    <Link to={`/apartment/${item.apart_id}/${item.as_id}/${item.apart_num}/${item.d_id}/${item.ad_id}/${item.direction}/${item.img}/${Number(params.fn*100)+Number(item.apart_num)}`} className={Number(item.sell_id) > 0 && item.confirm >0 ? "apartdes_selled" :Number(item.r_id) >0 ? "apartdes_reserved" :"apartdes"}>

    <div className="icon" ></div>
    <div className="imglink" >
          
             
              
             
              </div>
              <p className='title'>{Number(params.fn*100)+Number(item.apart_num)}  </p>


              </Link>
  
 
 

 
))}
</div>

</div>
  </div>
      )}
  </div>
  
)
  }

export default Getapartments;
import React, {useEffect,useState} from 'react'
import axios from 'axios';

import Table from 'react-bootstrap/Table';

import {useAuthUser} from 'react-auth-kit'
import DatalistInput from 'react-datalist-input';

const Clients_ = () => {

  const auth = useAuthUser();  




const [out, setOutput] = useState({
  name: '',
  client: '',
  any: '',
});





const handdle_change = (id) => {
  setOutput({
    ...out,
    ['client']:id
  }); 
 clients1(id);
}

useEffect( () => {

    clients();
  
}, []); 


const [loading, setLoading] = useState(false);

const [type, setType] = useState('');
const [buyers, setBuyers] = useState([]);
const [buyers1, setBuyers1] = useState([]);
const clients = async () => {
    setLoading(true);

  try {
    

   
    axios.get(`https://al-ansi.net/api/clients.php?username=`+auth().username+'&key='+auth().key)
    .then(res => {
        if(res.data.success===true){
    setBuyers(res.data.productlist.productdata);
    setBuyers1(res.data.productlist.productdata);
    setType(res.data.type)

  }else{
    alert(res.data.msg)
  }
      
      
    setLoading(false);

    })
  } catch (error) { throw error;}    

}




const clients1 = async (id) => {
  setLoading(true);

try {
  

 
  axios.get(`https://al-ansi.net/api/client_one.php?username=`+auth().username+'&key='+auth().key+'&b_id='+id)
  .then(res => {
      if(res.data.success===true){
  setBuyers(res.data.productlist.productdata);
  setType(res.data.type)

}else{
  alert(res.data.msg)
}
    
    
  setLoading(false);

  })
} catch (error) { throw error;}    

}


  
  
  const [file,setFile] = useState("");
    
  const setimgfile = (e)=>{
    setFile(e.target.files[0])
  }
  
  

const onChangeValue = (e) => {
  setOutput({
    ...out,
    [e.target.name]:e.target.value
  });

  
} 





var o=0;

return (
  
  <div >
     {loading ? (
      <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
        
      ) : ( 
<div>

 

   <div >
  

 
   <div className="list" style={{marginTop:90,marginBottom:50}}>

     
<form class="form"  autoComplete='off'>
    <p class="title" dir='rtl'>   العملاء </p>
 
      
     
   <hr></hr>
   <label for="textarea">ابحث واختر العميل...</label>

<DatalistInput

style={{color:'#000',}}
placeholder="العملاء"
autoSave='off'
onSelect={(item) => handdle_change(item.id)}
items={  buyers1.map(option => (
type === '1'?
  { id: option.buyer_id, value:option.name }
  :  { id: option.buyer_id, value:option.buyer_id }
  ))

}
/>
<hr></hr>
</form>


<br></br>


</div>
<div >
<Table  dir='rtl' responsive striped bordered hover variant="light">
    <thead>
    <tr>





    <th scope="col">رقم </th>
   
    {type === '1' ?
    <th scope="col">الاسم</th>
      :''
    }
    <th scope="col">تاريخ الانشاء</th>


       
   
   
  
        </tr>
        </thead>
  <tbody>

  {buyers.map((item,index)=>(
   
   

        <tr>
     
        <td>{item.buyer_id}</td>
     
        {type === '1' ?
        <td>{item.name}</td>
      :''
    }
        <td>{item.created_at}</td>



       </tr>
     
  

 

 
))}

</tbody>

  
</Table>
</div>
<br></br>

   </div>
       
         
  
  



  
</div>
)}





  </div>
)
}


export default Clients_;










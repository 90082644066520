import React,{useState} from 'react'
import { Link } from 'react-router-dom';




const Dash = () => {
   
   

    return (
  
  
        <div className='bg_img2' >
         
         <img className='center1' style={{position:'fixed' , zIndex:0}} src='.././logo2.png' />
              
        <div className='center1'>
        
        <h2>Dashboard</h2>
            <div className='container2'>
 
           <div className="list">
    <div className="container_dash" dir='rtl'>
    
        


  
    <Link className='dash_item'  to= "/buildings">
    <img className='utilities' src={".././b.png"}/>
  
    <p className='dash_item_title'><b> الأبراج </b>  </p>
    </Link>
      


        
    <Link className='dash_item'  to= "/users">
    <img className='utilities' src={".././team.png"}/>

    <p className='dash_item_title'><b> المستخدمين </b>  </p>
    </Link>
      
         
    <Link className='dash_item'  to= "/clients">
    <img className='utilities' src={".././clients.png"}/>

    <p className='dash_item_title'><b> العملاء </b>  </p>
    </Link>
      
       
             
    <Link className='dash_item'  to= "/reports">
    <img className='utilities' src={".././report.png"}/>

    <p className='dash_item_title'><b> التقارير </b>  </p>
    </Link>
      
  
    
    
    </div>
    </div> 
    </div> 
    </div>
  
       </div>
  
)
  }

export default Dash;










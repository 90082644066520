import React,{useState} from 'react'
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';

const Footer1 = () => {
   



    
return (

  
  <div  >

<Nav class="navbar fixed-bottom bg-dark"  defaultActiveKey={1} fill variant="underline">



      <Nav.Item >
<Link className="b_icon" title='الأبراج' to='/buildings'> <img src="https://al-ansi.net/b.png" style={{width:'24px'}}/> </Link>
      </Nav.Item>
      <Nav.Item >
      <Link className="b_icon" title='مستخدمين' to='/users'> <img src="https://al-ansi.net/team.png" style={{width:'24px'}}/> </Link>
      </Nav.Item>
      
      <Nav.Item >
      <Link className="b_icon" title='Dashboard' to='/'><img src="https://al-ansi.net/dashboard.png" style={{width:'24px'}}/> </Link>
      </Nav.Item>
      <Nav.Item >
      <Link className="b_icon" title='عملاء' to='/clients'><img src="https://al-ansi.net/clients.png" style={{width:'24px'}}/> </Link>
      </Nav.Item>
      <Nav.Item >
      <Link className="b_icon" title='تقارير' to='/reports'><img src="https://al-ansi.net/report.png" style={{width:'24px'}}/> </Link>
      </Nav.Item>
    </Nav>

                          
                        
                          
 
  </div>


)
  }

export default Footer1;










import React from 'react'
import { Link } from 'react-router-dom';



const Users_dash = () => {
   
   
    return (
  
  
      <div className='bg_img_c'>
        
        <img className='center1'  alt='' style={{position:'fixed' , zIndex:0}} src='.././logo2.png' />          
      
        <div className='center1' style={{marginTop:'40px'}}>
        
      
            <div className='container2' >
 
           <div className="list" >
           <h2>المستخدمين</h2>
    <div className="container_dash"  dir='rtl'>
    
        

    <Link className='dash_item' to= "/users/detail">
    <img className='utilities' alt='' src={".././team.png"}/>

    <p className='dash_item_title'><b> المستخدمين </b>  </p>
    </Link>
      
       

  
    <Link className='dash_item'  to= "/users/operations">
    <img className='utilities'  alt='' src={".././report.png"}/>
  
    <p className='dash_item_title'><b>  العمليات  </b>  </p>
    </Link>
      

    
    </div>
    </div> 
    <br></br>
    </div> 
    <br></br>
    </div>
   
       </div>
  
)
  }

export default Users_dash;










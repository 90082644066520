import React, {useEffect,useState} from 'react'
import axios from 'axios';

import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Footer1 from '../Footer1';
import {useAuthUser} from 'react-auth-kit'
import Modal from 'react-bootstrap/Modal';

const Users = () => {
   
   

    const auth = useAuthUser();  

const [out, setOutput] = useState({
  id:'',
    name: '',
    uname: '',
    email: '',
    type: '',
    edit: '0',
    delete: '0',
    add: '1',
  });
  
  
  
  
  
  useEffect( () => {
  
      users();
    
  }, []); 
  
  
  const [loading, setLoading] = useState(false);
  
  
  
  const [buyers, setBuyers] = useState([]);
  const users = async () => {
      setLoading(true);
  
    try {
     
      axios.get(`https://al-ansi.net/api/users.php?key=`+auth().key+'&username='+auth().username)
      .then(res => {
          if(res.data.success===true){
      setBuyers(res.data.productlist.productdata);
  
          } 
          else{
            alert(res.data.msg)
          }
        
      setLoading(false);
  
      })
    } catch (error) { throw error;}    
  
  }
  
  
    
    
   
    
  
  const onChangeValue = (e) => {
    setOutput({
      ...out,
      [e.target.name]:e.target.value
    });
  
    
  } 
  
  
  
  
  
  


  const add = async(event) => {
    setLoading(true);
     
     
    try {
      event.preventDefault();
      event.persist();
  
      var formData = new FormData();
  
      formData.append("username",auth().username); 
      formData.append("key",auth().key); 
   
      formData.append("name",out.name);  
      formData.append("uname",out.uname); 
      formData.append("email",out.email); 
      formData.append("type",out.type); 
      formData.append("delete",out.delete); 
      formData.append("edit",out.edit); 
      formData.append("add",out.add); 
  
      axios.post(`https://al-ansi.net/api/add_user.php`, formData)
      .then(res => {
            setLoading(false);
        
            alert(res.data.msg);
        
        window.location.reload(false);
       
       })
    } catch (error) { throw error;}    
  
  };







  const product = async (id) => {
  
    setLoading(true);
    try {
     
  
     
   
      
      axios.get('https://al-ansi.net/api/get_users.php?u_id='+id+'&key='+auth().key+'&username='+auth().username)
      .then(res => {
        if(res.data.success === true){
  
        setOutput(res.data.productlist.productdata[0])
         
          setLoading(false);
        }else{
          alert(res.data.msg)
          setLoading(false);
        }
      
      })
    } catch (error) { throw error;}    
   
  }
  
  
  
  const update = async() => {
    setLoading(true);
     
     
    try {

      var formData = new FormData();
  
      formData.append("username",auth().username); 
      formData.append("key",auth().key); 
   
      formData.append("u_id",u_id); 

      formData.append("name",out.name);  
      formData.append("uname",out.uname); 

      formData.append("delete",out.delete); 
      formData.append("edit",out.edit); 
      formData.append("add",out.add); 
  
      axios.post(`https://al-ansi.net/api/edit_user.php`, formData)
      .then(res => {
            setLoading(false);
        
            alert(res.data.msg);
        
        window.location.reload(false);
       
       })
    } catch (error) { throw error;}    
  
  };
  const [u_id, setU_id] = useState('');
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (id) =>{
   if(window.confirm( ` هل تريد تعديل المستخدم ؟   `)) {
  
    setU_id(id)
   product(id)
    setShow(true);
   }
    
  } 


  

  return (
    
    <div >
  
       {loading ? (
        <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
          
        ) : ( 
  <div>
  

  
     <div >
    






     <Modal  style={{color:'#999'}} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title >تعديل مستخدم </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
      
  <div className="list" >


  
                  
         
<form className="form" dir='rtl' style={{width:'90% ', maxWidth:'1000px'}} autoComplete='off' onSubmit={update}>
 
<p class="title">  تعديل مستخدم  </p>
      
<hr></hr>

<img className='center1' style={{position:'absolute' , zIndex:0}} src='.././logo2.png' />
    <label>
    <input className="input" type="text" name='name' placeholder="" value={out.name} onChange={onChangeValue} required/>
        <span>الأسم الكامل</span>
    </label> 
    <label>
    <input className="input" type="text" name='uname' placeholder="" value={out.uname} onChange={onChangeValue} required/>
        <span>أسم المستخدم</span>
    </label> 
    
       <hr></hr>
       <Row className="mb-3">
       <Form.Group    dir='ltr' >
       <label style={{marginLeft:'30%',marginBottom:'4px', color:'lightblue', backgroundColor:'#333333', width:'fit-content',padding:'10px',borderRadius:'15px'}} as="legend" column sm={2}>
       الأضافة
          </label>
          <div class="container">

          {
  out.add === '1' ?

  <form>

<label>
    <input name='add' type="radio" value='1'  defaultChecked onChange={onChangeValue} />
    <span>نعم</span>
     </label>
         
     <label>
    <input  name='add' type="radio" value='0'  onChange={onChangeValue}/>
    <span>لا</span>
     </label>
     </form>
     :

     <form>

<label>
    <input name='add' type="radio" value='1'   onChange={onChangeValue} />
    <span>نعم</span>
     </label>
         
     <label>
    <input  name='add' type="radio" value='0' defaultChecked onChange={onChangeValue}/>
    <span>لا</span>
     </label>
     </form>
  
}


</div>   
</Form.Group>

      <Form.Group   dir='ltr' className="mb-2" >
 
      <label style={{marginLeft:'30%',marginBottom:'4px', color:'orange', backgroundColor:'#333333', width:'fit-content',padding:'10px',borderRadius:'15px'}} as="legend" column sm={2}>
      التعديل
          </label>           
 
          <div  class="container">


          {
            out.edit === '1' ?

            <form>

<label>
<input type="radio" name='edit' value='1' defaultChecked onChange={onChangeValue} />
<span>نعم</span>
</label>
 
<label>
<input type="radio" name='edit' value='0'   onChange={onChangeValue} />
<span>لا</span>
</label>
</form>
            :

            <form>

<label>
<input type="radio" name='edit' value='1' onChange={onChangeValue} />
<span>نعم</span>
</label>
 
<label>
<input type="radio" name='edit' value='0'  defaultChecked onChange={onChangeValue} />
<span>لا</span>
</label>
</form>

          }
  
	
</div>   
</Form.Group>
   <Form.Group   dir='ltr' className="mb-2" >
   <label style={{marginLeft:'30%',marginBottom:'4px', color:'red', backgroundColor:'#333333', width:'fit-content',padding:'10px',borderRadius:'15px'}} as="legend" column sm={2}>
      الحذف
          </label>  
   <div  class="container">

{out.delete === '1' ?


<form>

<label>
<input type="radio" name='delete' value='1' defaultChecked onChange={onChangeValue} />
<span>نعم</span>
</label>
 
<label>
<input type="radio" name='delete' value='0'   onChange={onChangeValue} />
<span>لا</span>
</label>
</form>


:


<form>

<label>
<input type="radio" name='delete' value='1' onChange={onChangeValue} />
<span>نعم</span>
</label>
 
<label>
<input type="radio" name='delete' value='0'  defaultChecked onChange={onChangeValue} />
<span>لا</span>
</label>
</form>

}


</div>   
          
</Form.Group>
   </Row>
   <hr></hr>

   <Button variant="warning" type='submit' >
            تحديث
          </Button>

   
</form>
       
</div>
<br></br>

           
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>
   




     <h1>  المستخدمين </h1>
   
     <div className="list" style={{marginTop:90,marginBottom:50}}>
  <h2> اضافة مستخدم جديد </h2>

       
         
<form className="form"  autoComplete='off' onSubmit={add}>
    <p className="title" dir='rtl'>   المستخدمين </p>
    <p className="message"  dir='rtl'> اضافة مستخدم جديد  </p>
      
            
<img className='center1' style={{position:'absolute' , zIndex:0}} src='.././logo2.png' />
    <label>
    <input className="input" type="text" name='name' placeholder="" onChange={onChangeValue} required/>
        <span>الأسم الكامل</span>
    </label> 
    <label>
    <input className="input" type="text" name='uname' placeholder="" onChange={onChangeValue} required/>
        <span>أسم المستخدم</span>
    </label> 
    <label>
    <input className="input" type="email" name='email' placeholder="" onChange={onChangeValue} required/>
        <span>Email</span>
    </label>
   
    <label>
           
           <select name='type' style={{width:"50%"}} className="input" onChange={onChangeValue} required >
           <option value=''>choose...</option>
           <option value='1'>ادارة</option>
           <option value='2'>هندسة</option>
           <option value='3'>مبيعات</option>
           </select>
           <span>نوع الحساب</span>
       </label>
       <hr></hr>
       <Row className="mb-3">
       <Form.Group  as={Col}  dir='ltr' >
       <label style={{marginLeft:'30%',marginBottom:'4px', color:'lightblue', backgroundColor:'#333333', width:'fit-content',padding:'10px',borderRadius:'15px'}} as="legend" column sm={2}>
       الأضافة
          </label>
          <div class="container">
	<form>

          <label>
			<input name='add' type="radio" value='1'  defaultChecked onChange={onChangeValue} />
			<span>نعم</span>
		   </label>
           
       <label>
			<input  name='add' type="radio" value='0'  onChange={onChangeValue}/>
			<span>لا</span>
		   </label>
       </form>
</div>   
</Form.Group>

      <Form.Group as={Col}  dir='ltr' className="mb-2" >
 
      <label style={{marginLeft:'30%',marginBottom:'4px', color:'orange', backgroundColor:'#333333', width:'fit-content',padding:'10px',borderRadius:'15px'}} as="legend" column sm={2}>
      التعديل
          </label>           
 
          <div  class="container">
  
	<form>

          <label>
			<input type="radio" name='edit' value='1' onChange={onChangeValue} />
			<span>نعم</span>
		   </label>
           
       <label>
			<input type="radio" name='edit' value='0'  defaultChecked onChange={onChangeValue} />
			<span>لا</span>
		   </label>
       </form>
</div>   
</Form.Group>
   <Form.Group as={Col}  dir='ltr' className="mb-2" >
   <label style={{marginLeft:'30%',marginBottom:'4px', color:'red', backgroundColor:'#333333', width:'fit-content',padding:'10px',borderRadius:'15px'}} as="legend" column sm={2}>
      الحذف
          </label>  
   <div  class="container">
  
	<form>

          <label>
			<input type="radio" name='delete' value='1' onChange={onChangeValue} />
			<span>نعم</span>
		   </label>
           
       <label>
			<input type="radio" name='delete' value='0'  defaultChecked onChange={onChangeValue} />
			<span>لا</span>
		   </label>
       </form>
</div>   
          
</Form.Group>
   </Row>
   <hr></hr>
    <button class="submit">أضافة</button>
   
</form>
       
   
  </div>
  <div >
  <Table dir='rtl' responsive striped bordered hover variant="light">
      <thead>
      <tr>
  
  
  
  
  
      <th scope="col">رقم </th>
      <th scope="col">الاسم</th>
      <th scope="col">اسم المستخدم</th>
      <th scope="col">البريد</th>
      <th scope="col">النوع</th>
      <th scope="col">أضافة</th>
      <th scope="col">تعديل</th>
      <th scope="col">الحذف</th>
      <th scope="col">قام بالأضافة</th>
      <th scope="col">تاريخ الانشاء</th>
      <th scope="col">---</th>
      <th scope="col">---</th>
    
     
     
    
          </tr>
          </thead>
    <tbody>
  
    {buyers.map((item,index)=>(
     
     item.is_admin === '1' ?
  
     <tr>
       
       <td>{item.id}</td>
       <td>{item.name}</td>
       <td>{item.username}</td>
       <td>{item.email}</td>
      
         <td colSpan={8}>حساب رئيسي <b>(ADMIN)</b></td>
     
      </tr>
    :
          <tr>
       
          <td>{item.id}</td>
          <td>{item.name}</td>
          <td>{item.username}</td>
          <td>{item.email}</td>
          <td>{item.type === '1' ?        
          "ادارة"
          :item.type === '2' ?
          "هندسة"
          :"مبيعات"
          
          }</td>
          <td>{item.add === '1' ? 'نعم' : 'لا'}</td>
          <td>{item.edit === '1' ? 'نعم' : 'لا'}</td>
          <td>{item.delete === '1' ? 'نعم' : 'لا'}</td>
          
          <td>{item.u_name}</td>
            <td>{item.created_at}</td>
            <td><Button variant="outline-warning" onClick={() =>handleShow((item.id))}>
          تعديل
        </Button></td> 
          <td><p  className="btn default-btn" > الغاء تفعيل ؟ </p> </td>     
        
         </tr>
       
    
  
   
  
   
  ))}
  
  </tbody>
  
    
  </Table>
  </div>
  <br></br>
  <br></br>
     </div>
         
           
    
    
  
  
  
    
  </div>
  )}
  
  
  
  
  <Footer1/>
    </div>
  )
  }
  
export default Users;










import React, {useEffect,useState} from 'react'
import axios from 'axios';
import {  useParams } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import DatalistInput from 'react-datalist-input';
import Modal from 'react-bootstrap/Modal';
import 'react-datalist-input/dist/styles.css';
import {useAuthUser} from 'react-auth-kit'
import Spinner from 'react-bootstrap/Spinner';

const Elements = (props) => {
  let params = useParams();
  const auth = useAuthUser();  
  const [out, setOutput] = useState({
    cat: '0',
    subcat: '0',
    count:1,
    d_id:params.id,
  
  });
  
  useEffect( () => {

   
  
    cat();

    
 
    allproduct(params.id);
   

}, []); 


const [spin, setSpin] = useState(false);

const [emp, setEmp] = useState([]);
const [emp1, setEmp1] = useState([]);




const cat = async () => {


  try {
   
    axios.get(`https://al-ansi.net/api/categories.php`)
    .then(res => {
     
      setEmp(res.data.productlist.productdata);
     

    })
  } catch (error) { throw error;}    

}





const onChangeValue = (e) => {
  setOutput({
    ...out,
    [e.target.name]:e.target.value
  });

  
} 
const onChangeValue1 = (id) => {
  setOutput({
    ...out,
    ['cat']:id
    , ['subcat']:0
  } );
  const value = id;

  setEmp1([]);
  subcat(value);
  
} 



const onChangeValue2 = (id) => {
  setOutput({
    ...out,
    ['subcat']:id
  });

  
} 



const subcat = async (cat) => {
  setSpin(true)

  try {
   
    axios.get(`https://al-ansi.net/api/subcat.php?cat=`+cat)
    .then(res => {
     
    
      setEmp1(res.data.productlist.productdata);
      
      setSpin(false)
    })
  } catch (error) { throw error;}    

}


const [project, setProject] = useState([]);


const [loading, setLoading] = useState(false);

const allproduct = async (id) => {
  
  setLoading(true);
  try {
   

   
 
    
    axios.get('https://al-ansi.net/api/elements.php?d_id='+id)
    .then(res => {
      if(res.data.success === true){
        setProject(res.data.productlist.productdata);
        setLoading(false);
      }else{
        alert(res.data.msg)
        setLoading(false);
      }
    
    })
  } catch (error) { throw error;}    
 
}



const [element, setElement] = useState([]);
const product = async (id) => {
  
  setLoading(true);
  try {
   

   
 
    
    axios.get('https://al-ansi.net/api/element.php?dd_id='+id)
    .then(res => {
      if(res.data.success === true){
        setElement(res.data.productlist.productdata[0]);
        setLoading(false);
      }else{
        alert(res.data.msg)
        setLoading(false);
      }
    
    })
  } catch (error) { throw error;}    
 
}



const update = async(dd_id) => {
  setLoading(true);
   
  try {


    var formData = new FormData();

    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
 
   
    
    formData.append("cat_id",out.cat); 
    formData.append("sc_id",out.subcat); 
    formData.append("d_id",out.d_id);
    formData.append("dd_id",dd_id);

    axios.post(`https://al-ansi.net/api/edit_des_det.php`, formData)
    .then(res => {
          setLoading(false);
      alert(res.data.msg);
      
      window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};

const [dd_id, setDd_id] = useState('');

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = (id) =>{
 if(window.confirm( ` هل تريد تعديل العنصر   `)) {

  setDd_id(id)
  product(id)
  setShow(true);
 }
  
} 



var r=0;
var c=0;
var b=0;
return (
  
  <div>
 
  <h1>العناصر</h1>
  
 
    {loading ? (
      <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
      ) : ( 


        <div>
        <Modal  style={{color:'#999'}} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title >تعديل عنصر قي مجموعة ({element.group})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
      
  <div className="list" >

   
    
<div dir='rtl' style={{width:'90% ', maxWidth:'1000px'}} class="form-container">
     

     
     
         <p class="title">  تعديل عنصر  </p>
     
          
             <hr></hr>
<form >


<div dir='' class="form-group">
<div style={{width:'100%'}} >




<DatalistInput
style={{color:'#1a1a1a'}}

dir='rtl'
 placeholder="Search and choose..."
 label=" النوع :"
 onSelect={(item) => onChangeValue1(item.id)}
 items={ emp.map(option => (

   { id: option.value, value:option.label }
   
   ))

 }
/>





<br />
<mark>الخيار السابق : {element.cat}</mark>

<br /><br />


{
 spin ?  <Spinner animation="border" />
 :
<DatalistInput
style={{color:'#1a1a1a'}}

dir='rtl'
 placeholder="Search and choose..."
 label=" تفصيل النوع :"
 onSelect={(item) => onChangeValue2(item.id)}
 items={ emp1.map(option => (

   { id: option.value, value:option.label }
   
   ))

 }
/>
}
<br />
<mark>الخيار السابق : {element.sub}</mark>
</div>
<hr></hr>


<Button variant='warning' onClick={() => update(dd_id)}>تعديل</Button>

</div>







</form>
</div>
<br></br>
</div>
           
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>
   
        <Table  responsive striped bordered hover variant="light">
    <thead>
    <tr>
   
  <th scope="col">تعديل</th>
  <th scope="col">العدد</th>
    <th scope="col">الغنصر</th>
    <th scope="col">النوع </th>
    <th scope="col">المجموعه</th>
    
    
   
    
       
   
   
  
        </tr>
        </thead>
  <tbody>
  {project.map((item,index)=>(
   
   

      

        <tr>
 
        <td><Button variant="outline-warning" onClick={() =>handleShow((item.dd_id))}>
          تعديل
        </Button></td> 
        <td>{item.count}</td>
        <td>{item.sub}</td>
        <td>{item.cat}</td>
        <td>{item.group}</td>
        
        {
            item.cat_id === "1" ?

        <td className='hide' >{r += Number(item.count) } </td>
        
            : item.cat_id === "2" ?

        <td className='hide' >{b += Number(item.count) } </td>

             : item.cat_id === "3" ?

        <td className='hide' >{c += Number(item.count) } </td>

:""


         }
       
       </tr>
     
  

 

 
))}

</tbody>

   
<tfoot>
  

  <tr>
    <td colSpan={4}><b>{r}</b></td>
    <th  scope="row">:عدد العرف</th>
    </tr>
  
  
    <tr>
    <td colSpan={4}><b>{b}</b></td>
    <th  scope="row">:الحمامات</th>
    </tr>
  
    <tr>
    <td colSpan={4}><b>{c}</b></td>
    <th  scope="row">:المطبخ</th>
    </tr>
  
  
  
    </tfoot>
</Table>
</div>





      )}


    
  </div>
)
  }

export default Elements;










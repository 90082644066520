
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';

const Footer = () => {
   

    
return (


  <div  >

<Nav class="navbar fixed-bottom bg-dark" defaultActiveKey={1} fill variant="underline">



      <Nav.Item >
<Link className="b_icon" title='عرض البرج' to={`/building/`+localStorage.getItem('b_id')}> <img src="https://al-ansi.net/building_b.png"/> </Link>
      </Nav.Item>
      <Nav.Item>
      <Link className="b_icon" title='المخططات' to={`/building/${localStorage.getItem('b_id')}/apart_sec`}> <img src="https://al-ansi.net/blueprint_b.png"/> </Link>
      </Nav.Item>
      <Nav.Item>
      <Link className="b_icon" title='التشطيبات' to={`/building/${localStorage.getItem('b_id')}/apart_utl`}><img src="https://al-ansi.net/interior-design.png"/> </Link>
      </Nav.Item>
    
    </Nav>

                          
                        
                          
 
  </div>


)
  }

export default Footer;










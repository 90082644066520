import React, {useEffect,useState} from 'react'


import Header_in from '../Header_in';
import Getsections from './Getsections';
import Footer from '../Footer';

const Apart_sec = () => {
   




return (
  
  <div className='bg_img'>


  <Getsections/>
 

  </div>
  
)
  }

export default Apart_sec;










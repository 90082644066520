import {  useState ,useEffect} from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { useSignIn } from "react-auth-kit";
import {useIsAuthenticated} from 'react-auth-kit';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Popup from './Popup';



const Signin = () => {

  

  const navigate = useNavigate();
  const signIn = useSignIn()
  const [userInfo, setuserInfo] = useState({
    name: '',
    email: '',
    password: '',
  });
  const isAuthenticated = useIsAuthenticated();

  useEffect( () => {
   
    if(isAuthenticated()){
      navigate(`/`);
    }
    else {
      navigate(`/login`);
    }
    


 },[]); 

  



   
   
const [loading, setLoading] = useState(false);
  const onChangeValue = (e) => {
    setuserInfo({
      ...userInfo,
      [e.target.name]:e.target.value
    });
  } 
  // Inserting a new user into the Database.
  const loginUser = async(event) => {
  
    setLoading(true);

    try {


      event.preventDefault();
      event.persist();
      
      localStorage.clear();

      var formData = new FormData();
      
      formData.append("username",userInfo.name); 
      
      formData.append("userpassword",userInfo.password); 
      
     


      axios.post(`https://al-ansi.net/api/signin.php`,formData )
      .then(res => {
       if(res.data.Status==='1'){

        signIn({
          token: res.data.key,
          expiresIn: 1440,
          tokenType: "Bearer",
          authState: { username:res.data.username,key:res.data.key,name:res.data.name,type:res.data.type},
        });
     
        navigate(`/`);
       }else{
        
   
        setLoading(false);
        alert('Invalid User');
       }
        
        
       })
    } catch (error) { throw error;} 
  
  };







  const [isconfirm, setIsconfirm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

const [v_code, setV_code] = useState(0);
const [pas, setPas] = useState('');


const setdata = (e)=>{
  setV_code(e.target.value);
 
}

const setdata1 = (e)=>{
  setPas(e.target.value);
 
}


const togglePopup = () => {

  setV_code();
  if(isOpen === true){

  }else{
    verfication();
  }
  
  setIsOpen(!isOpen);
 
  }


const togglePopup1 = () => {
 
  setIsconfirm(!isconfirm);
 
  }



  const verfication = async()=>{
 

  
    
     
      
      var formData = new FormData();
      
      formData.append("username",userInfo.name);  
      
      
      
      try {
      
       await axios.post("https://al-ansi.net/api/email1.php",formData)
     
       .then(res => {
       
      })
     
      } catch (ex) {
        console.log(ex);
       
      
      }
    
  };
  
  const alertConfirm = () => {
    if (window.confirm("Are you sure you want confirm ?")) {
      togglePopup();
    }
  }; 


  
const checkverfication = async(v_code) => {
 
 


  
  
  var formData = new FormData();
  
  formData.append("username",userInfo.name); 
 
  formData.append("code",v_code);
  
  
  try {
  
   await axios.post("https://al-ansi.net/api/checkver1.php",formData)
 
   .then(res => {
    if(res.data.Status==='1'){
     
      setIsconfirm(true);
    
      setIsOpen(false);
    }else{

      alert('Invalid code!');
      
    }
    
  })
 
  } catch (ex) {
    console.log(ex);
   
  
  } 
}; 




const changepassword = async(pas) => {
  setLoading(true);
  
  if(pas.length<=8){
    alert('اخل كلمة سر اكبر من 8 ارقام او احرف');
   
  }else{

 

  
  var formData = new FormData();
  
  formData.append("username",userInfo.name); 
 
  formData.append("password",pas);




  
  
  try {
  
   await axios.post("https://al-ansi.net/api/changepassword1.php",formData)
 
   .then(res => {
    if(res.data.Status==='1'){
     
      alert('تم تغيير كلمة السر بنجاح');


      window.location.reload(false);
     

    }else{

      alert('فشل تغيير كلمة السر ');
      
    }
    
  })
 
  } catch (ex) {
    console.log(ex);
   
  
  } finally{
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }  
  }
}; 
  







return (
 <div>

  {loading ? (
    <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
        
      ) : (  <div >
      <Navbar bg="dark" data-bs-theme="dark">
             <Container>
               <Navbar.Brand className="name" href="#home">
               <Navbar.Brand >  <img
           
           alt=""
           src={'https://al-ansi.net/logo2.png'}  
          
           width="50px"
      
         /></Navbar.Brand>
               </Navbar.Brand>
               <Navbar.Toggle />
             <Navbar.Collapse className="justify-content-end">
               <Navbar.Text>
                 Developed by : <a href="#login">Future Swift</a>
               </Navbar.Text>
             </Navbar.Collapse>
             </Container>
           </Navbar>
         

           <div className="list" style={{marginTop:50,marginBottom:90}}>  

 <br></br>
    <br></br>







    <form className="form"   onSubmit={loginUser}>
    <p className="title">Login </p>
    <p className="message"> Welcome Back</p>
           
<img className='center1' style={{position:'absolute' , zIndex:0}} src='.././logo2.png' />
    <br></br>
 
    <label>
        <input className="input" type="text" name="name" onChange={onChangeValue} placeholder="" required/>
        <span>Username</span>
    </label>
   
    <label>
        <input className="input" type="password" name="password" onChange={onChangeValue} placeholder="" required/>
        <span>password</span>
    </label>
    <br></br>
    <button type="submit" style={{zIndex:1}} className="submit">Login</button>
   <br></br>
   <br /> <br />
  
 
<Nav class="navbar " fill>

<Nav.Item >
<a className="b_icon" title='عرض البرج' href=''> <img style={{width:'32px'}} src=".././instagram.png"/> </a>
</Nav.Item>
<Nav.Item>
<a className="b_icon" title='المخططات' href=''> <img style={{width:'32px'}} src=".././facebook.png"/> </a>
</Nav.Item>
<Nav.Item>
<a className="b_icon" title='التشطيبات' href=''><img style={{width:'32px'}}  src=".././whatsapp.png"/> </a>
</Nav.Item>

</Nav>
  <br /> <br />
  {userInfo.name === '' ? <Button  disabled onClick={alertConfirm} variant="outline-light">لقد نسيت كلمة السر</Button>
 :
 <Button   onClick={alertConfirm} style={{zIndex:1}} variant="outline-light">لقد نسيت كلمة السر</Button>
 }
   <br></br>
   <Navbar.Text dir="rtl">
   <p class="message">  Developed by : <strong><a href="#Future_swift">Future Swift</a></strong></p> 
               </Navbar.Text>
</form>



  </div>



  


  
 {isOpen && <Popup
      content={<>
        <h4><b>Verfication Code </b></h4>
        <hr></hr>
        <p>للتأكيد<br></br> 
        يرجى ادخال الكود<br></br> 
        الذي تم ارسالة الى ايميلك </p>
        
        
        <hr></hr>
     
        <label htmlFor="_verication">Verfication Code</label>
        <input onChange={setdata} id='_verication' name='verication' className='confirm' type="text" required/>
        <br></br>
   
        <Button onClick={() => checkverfication(v_code)} variant='success' >تأكيد</Button>
      </>}
      handleClose={togglePopup}
      />}




{isconfirm && <Popup
      content={<>
        <h4><b>Change password</b></h4>
        <hr></hr>
        <p>لتغيير كلمة السر<br></br> 
       يرجى ادخال كلمة السر الجديدة
       <br></br></p>
        
        <hr></hr>

        <label htmlFor="_password">New Password</label>
      <input
        type="password"
        id="_password"
        name="pass"
        onChange={setdata1}
        placeholder="Enter Password"
        autoComplete="off"
        required
      />
      <br /> <br />
        <br></br>
       
        <Button onClick={() => changepassword(pas)} variant='success' >تأكيد</Button>
    
      </>}
      handleClose={togglePopup1}
      />}
  </div>
 )}





  </div>
);
};

export default Signin;

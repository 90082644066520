import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "react-auth-kit"
import { CookiesProvider } from "react-cookie";

ReactDOM.render(


  <AuthProvider
  authType={"cookie"}
  authName={"_auth"}
  cookieDomain={window.location.hostname}
  cookieSecure={false}
>
   <CookiesProvider>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </CookiesProvider>
  </AuthProvider>
  
  
  ,
  document.getElementById("root")
  
);


reportWebVitals();
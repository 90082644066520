import React from 'react'


import Header from '../Header';
import Getbuildings from './GetBuildings';
import Footer1 from '../Footer1';
import { Outlet } from 'react-router-dom';


const Home = () => {




return (
  
  <div className='bg' >
<Header />

<Outlet />
<br></br>
<br></br>
<Footer1/>
   </div>
)
  }

export default Home;










import React, {useEffect,useState} from 'react'




import Getsections2 from './Getsections2';


const Apart_utl = () => {
   




return (
  
  <div className='bg_img1'>


  <Getsections2/>

  </div>
  
)
  }

export default Apart_utl;










import React from 'react'

import Header_in from '../Header_in';
import Getapartments from './Getapartments';
import Getapartment from './Getapartment';

const Apartment = () => {
   

return (
  
  <div className='bg_img'>

<Getapartment />
     
  </div>
  
)
  }

export default Apartment;









